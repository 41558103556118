export const idParam = ":id";
export const oidParam = ":oid";

export const resetPasswordPageUrlResult = `/reset-result`;
export const recoverPageUrlResult = `/recover-result`;
export const profileUrl = `/profile`;
export const loginPageUrl = `/login`;
export const resetPasswordPageUrl = `/recover`;
export const recoverPageUrl = `/auth/recover/end`;
export const mainUrl = `*`;
export const alarmsPageUrl = `/alarms`;
export const updateMantainerUrl = (id: string) => `/mantainer/update/${id}`;
export const assignMantainerUrl = `/mantainer/assign`;
export const elevatorsHistoryUrl = `/elevator/history`;
export const createUsersUrl = `/users/new`;
export const usersListUrl = `/users/list`;
export const createOrdersUrl = `/orders/new`;
export const ordersListUrl = `/orders/list`;
export const assignedResourcesUrl = (id: string) => `/orders/${id}/resources`;
export const assignedResourcesManagerUrl = (id: string) =>
  `/orders/${id}/resources/manager`;
export const assignResourcesUrl = `/orders/resources/assign`;
export const getResourcesToAssignUrl = (id: string) =>
  `/orders/resources/${id}/assign`;
export const getResourceUrl = (id: string) => `/orders/resources/${id}/assign`;
export const updateResourceUrl = (id: string) =>
  `/orders/resources/update/${id}`;
export const deleteResourceUrl = (id: string) =>
  `/orders/resources/delete/${id}`;
export const infoManagementUrl = `/info-management`;
export const cranesListUrl = `/cranes`;
export const createCraneUrl = `/cranes/new`;
export const updateCraneUrl = (id: string) => `/cranes/update/${id}`;
export const deleteCraneUrl = (id: string) => `/cranes/delete/${id}`;
export const craneOperatorsListUrl = `/cranes-operators`;
export const createCraneOperatorUrl = `/cranes-operators/new`;
export const updateCraneOperatorUrl = (id: string) =>
  `/cranes-operators/update/${id}`;
export const deleteCraneOperatorUrl = (id: string) =>
  `/cranes-operators/delete/${id}`;
export const signalersListUrl = `/signalers`;
export const createSignalerUrl = `/signalers/new`;
export const updateSignalerUrl = (id: string) => `/signalers/update/${id}`;
export const dockModeListUrl = `/docks/modes`;
export const createDockModeUrl = `/docks/modes/new`;
export const updateDockModeUrl = (id: string) => `/docks/modes/update/${id}`;
export const dockNumListUrl = `/docks/numbers`;
export const createDockNumUrl = `/docks/numbers/new`;
export const updateDockNumUrl = (id: string) => `/docks/numbers/update/${id}`;
export const shiftsUrl = `/shifts`;
export const dashboardControllerUrl = `/dashboard/controller`;
export const getDashboardControllerResultUrl = `/dashboard/controller/result`;
export const getDashboardControllerUrl = (
  oid: string,
  batch_id: string,
  shift_id: string,
  crane_id: string,
  date: string,
  home: boolean = false
) =>
  `/dashboard/controller/result?oid=${oid}&batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&date=${date}&home=${home}`;
export const dashboardManagerUrl = `/dashboard/manager`;
export const getDashboardManagerUrl = (
  oid: string,
  batch_id: string,
  batch_status: string,
  shift_id: string,
  crane_id: string,
  crane_descr: string,
  start: string,
  end: string
) =>
  `/dashboard/manager/result?oid=${oid}&batch_status=${batch_status}&batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&crane_descr=${crane_descr}&start=${start}&end=${end}`;
export const getDashboardManagerResultUrl = `/dashboard/manager/result`;
export const getDashboardManagerResultDetailsUrl = `/dashboard/manager/result/details`;
export const getDashboardManagerResultGearUrl = `/dashboard/manager/result/gear`;
export const getDashboardManagerResultGearDetailsUrl = `/dashboard/manager/result/gear/details`;
export const updateUserUrl = (id: string) => `/users/${id}`;
export const updateOrderUrl = (id: string) => `/orders/update/${id}`;
export const deleteUserUrl = (id: string) => `/users/${id}`;
export const resetPasswordUrl = (id: string) => `/users/${id}/reset-password`;
export const assignUserUrl = `/users/assign`;
export const deleteElevatorUrl = `/elevator/delete`;
export const createMaintenanceUrl = `/maintenance/new`;
export const maintenanceListUrl = `/maintenance/list`;
export const maintenanceHistoryUrl = `/maintenance/history`;
export const createAdminUrl = `/admin/new`;
export const adminListUrl = `/admin/list`;
export const assignBoardTypeUrl = `/board/assign`;
export const alarmsThresholdUrl = (id: string) => `/alarms/${id}/threshold`;
export const settingsUrl = `/settings`;
export const rawMessagesUrl = `/raw-messages`;
export const updateMessageUrl = (id: string) => `/message/update/${id}`;

export const routes = {
  profile: profileUrl,
  mainPage: mainUrl,
  login: loginPageUrl,
  recoverStart: resetPasswordPageUrl,
  recoverEnd: recoverPageUrl,
  resetResult: resetPasswordPageUrlResult,
  recoverResult: recoverPageUrlResult,
  alarms: alarmsPageUrl,
  assignMantainer: assignMantainerUrl,
  elevatorsHistory: elevatorsHistoryUrl,
  updateMantainer: updateMantainerUrl(idParam),
  createUsers: createUsersUrl,
  usersList: usersListUrl,
  createOrders: createOrdersUrl,
  ordersList: ordersListUrl,
  updateOrder: updateOrderUrl(idParam),
  assignedResources: assignedResourcesUrl(idParam),
  assignedResourcesManager: assignedResourcesManagerUrl(idParam),
  getAssignedResourcesUrl: assignedResourcesUrl,
  assignResources: assignResourcesUrl,
  getResourcesToAssign: getResourcesToAssignUrl(idParam),
  getResourcesToAssignWithId: getResourcesToAssignUrl,
  updateResource: updateResourceUrl(idParam),
  deleteResource: deleteResourceUrl(idParam),
  getResource: getResourceUrl(idParam),
  infoManagement: infoManagementUrl,
  rawMessages: rawMessagesUrl,
  cranesList: cranesListUrl,
  createCrane: createCraneUrl,
  updateCrane: updateCraneUrl(idParam),
  craneOperatorsList: craneOperatorsListUrl,
  createCraneOperator: createCraneOperatorUrl,
  updateCraneOperator: updateCraneOperatorUrl(idParam),
  signalersList: signalersListUrl,
  createSignaler: createSignalerUrl,
  updateSignaler: updateSignalerUrl(idParam),
  dockModeList: dockModeListUrl,
  createDockMode: createDockModeUrl,
  updateDockMode: updateDockModeUrl(idParam),
  dockNumList: dockNumListUrl,
  createDockNum: createDockNumUrl,
  updateDockNum: updateDockNumUrl(idParam),
  dashboardController: dashboardControllerUrl,
  getDashboardControllerResult: getDashboardControllerResultUrl,
  dashboardManager: dashboardManagerUrl,
  getDashboardManagerResult: getDashboardManagerResultUrl,
  getDashboardManagerResultDetails: getDashboardManagerResultDetailsUrl,
  getDashboardManagerResultGear: getDashboardManagerResultGearUrl,
  getDashboardManagerResultGearDetails: getDashboardManagerResultGearDetailsUrl,
  shifts: shiftsUrl,
  updateUser: updateUserUrl(idParam),
  deleteUser: deleteUserUrl(idParam),
  resetPassword: resetPasswordUrl(idParam),
  assignUser: assignUserUrl,
  deleteElevator: deleteElevatorUrl,
  createMaintenance: createMaintenanceUrl,
  maintenanceList: maintenanceListUrl,
  maintenanceHistory: maintenanceHistoryUrl,
  createAdmin: createAdminUrl,
  adminList: adminListUrl,
  assignBoardType: assignBoardTypeUrl,
  alarmsThreshold: alarmsThresholdUrl(idParam),
  settings: settingsUrl,
  updateMessage: updateMessageUrl(idParam),
};

export const roles = {
  admin: "admin",
  controller: "controller",
  manager: "manager",
};

export const isDev =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
