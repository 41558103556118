import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { assignedResourcesUrl, mainUrl, routes } from "../costants";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import {
  assignResource as assignResourceApi,
  getAvailableResources,
} from "../../../api/services/resourceService";
import { useSnackbar } from "notistack";
import { AssignResourceRequest } from "../../../api/requests/resourceService";
import CustomLoading from "../../custom/CustomLoading";
import CustomSelect from "../../custom/CustomSelect";
import { getProfile } from "../../../api/services/userService";
import CustomTag from "../../custom/CustomTag";
import { redColor } from "../../custom/utils";

type AssignResourcesProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
};

const AssignResources: React.FC<AssignResourcesProps> = ({
  setBreadcrumb,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams(); //order uid
  const orderid = id?.split("-")[0];
  const oid = id?.split("-")[1];
  const [cranes, setCranes] = useState<any[]>([]);
  const [craneOperators, setCraneOperators] = useState<any[]>([]);
  const [signalers, setSignalers] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const [values, setValues] = useState({
    oid : "",
    order_id: "",
    crane: 0,
    crane_operator: 0,
    shift: "",
    signaler: 0,
    controller: "",
    date_shift: "",
  });

  const [dateShift, setDateShift] = useState({
    date_shift: "",
    shift: "",
  });

  // Function to get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Function to get the current date minus one day in YYYY-MM-DD format
  const getCurrentDateMinusOneDay = () => {
    const givenDate = new Date(); // Data di partenza (23 ottobre 2024, 01:00 ora locale di Roma)
    console.log("Data di partenza:", givenDate.toString()); // Visualizza la data di partenza

    // Sottrai 24 ore in millisecondi direttamente dalla data di partenza
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    const newDate = new Date(givenDate.getTime() - oneDayInMillis);
    console.log("Data finale:", newDate.toString()); // Visualizza la data finale

    // Formattare la data manualmente nel formato YYYY-MM-DD (locale)
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Mese è 0-indexed
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // useEffect to update the date_shift when the shift changes
  useEffect(() => {
    let newDateShift = "";
    if (
      dateShift.shift === "1" ||
      dateShift.shift === "2" ||
      dateShift.shift === "3"
    ) {
      newDateShift = getCurrentDate();
    } else if (dateShift.shift === "4") {
      newDateShift = getCurrentDateMinusOneDay();
    }

    setDateShift((prevState) => ({
      ...prevState,
      date_shift: newDateShift,
    }));
  }, [dateShift.shift]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    getProfile().then((res) => {
      if (res && res.user) {
        setValues({ ...values, controller: res.user.email });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    if (dateShift.date_shift === "" || dateShift.shift === "") {
      enqueueSnackbar(t("fillDateShift"), { variant: "error" });
    } else {
      getAvailableResources(oid!, dateShift.date_shift, dateShift.shift).then(
        (res: any) => {
          if (res && res.resources) {
            if (
              res.resources.cranes == null &&
              res.resources.crane_operators == null &&
              res.resources.signalers == null
            ) {
              enqueueSnackbar(t("noResourcesAvailable"), { variant: "error" });
            } else {
              if (res.resources.orders && res.resources.orders.length > 0) {
                setOrders([...res.resources.orders]);
              }

              if (res.resources.cranes && res.resources.cranes.length > 0) {
                setCranes([...res.resources.cranes]);
              } else {
                setCranes([]);
              }

              if (
                res.resources.crane_operators &&
                res.resources.crane_operators.length > 0
              ) {
                setCraneOperators([...res.resources.crane_operators]);
              } else {
                setCraneOperators([]);
              }

              if (
                res.resources.signalers &&
                res.resources.signalers.length > 0
              ) {
                setSignalers([...res.resources.signalers]);
              } else {
                setSignalers([]);
              }
              setVisible(true);
            }
          } else {
            enqueueSnackbar(t("errorGettingResources"), { variant: "error" });
          }
        }
      );
    }
  };

  const handleSubmitAssign = () => {

    let oidElem = "";

    if (id) {
      values.order_id = orderid!;
      values.oid = oid!
    } else {
      values.oid = "";
    }

    if (
      values.crane === 0 ||
      values.crane_operator === 0 ||
      values.signaler === 0
    ) {
      enqueueSnackbar(t("fillAllFields"), { variant: "error" });
    } else {
      assignResourceApi({
        oid: values.oid,
        order_id: values.order_id,
        crane: values.crane,
        crane_operator: values.crane_operator,
        shift: dateShift.shift,
        signaler: values.signaler,
        controller: values.controller,
        date_shift: dateShift.date_shift,
      } as AssignResourceRequest).then((res) => {
        if (res && res.resources) {
          setValues({
            oid: "",
            order_id: "",
            crane: 0,
            crane_operator: 0,
            shift: "",
            signaler: 0,
            controller: "",
            date_shift: "",
          });
          enqueueSnackbar(t("resourcesAssignedSuccess"), {
            variant: "success",
          });
          navigate(assignedResourcesUrl(res.resources.order_id + "-" + oid));
        } else {
          enqueueSnackbar(
            `${t("resourcesAssignedError")} ${res?.err?.message}`,
            {
              variant: "error",
            }
          );
        }
      });
    }
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        {id ? (
          <CustomTitle
            title={t(title) + " " + t("order") + " " + orderid}
            goBack={() => {
              navigate(routes.getAssignedResourcesUrl(id!));
            }}
          />
        ) : (
          <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          header={{
            visible: true,
            title: `${t("insertDateShift")}`,
          }}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("shift")}`}
                  value={dateShift.shift}
                  options={[
                    { label: t("shift1"), value: "1" },
                    { label: t("shift2"), value: "2" },
                    { label: t("shift3"), value: "3" },
                    { label: t("shift4"), value: "4" },
                  ]}
                  handleChange={(e) => {
                    setDateShift({ ...dateShift, shift: e.target.value });
                    setVisible(false);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("dateShift")}
                  type="date"
                  value={dateShift.date_shift}
                  handleChange={(e) => {
                    setDateShift({ ...dateShift, date_shift: e.target.value });
                    setVisible(false);
                  }}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
      {visible ? (
        <Grid item xs={12} md={8}>
          <CustomCard
            header={{
              visible: true,
              title: `${t("assignAvailableResources")}`,
            }}
            content={
              <Grid container spacing={2}>
                {!id ? (
                  <Grid item xs={12} md={6}>
                    <CustomSelect
                      label={`${t("orderId")}`}
                      value={values.order_id}
                      options={orders.map((order) => ({
                        value: order.order_id,
                        label: order.order_id,
                      }))}
                      handleChange={(e) =>
                        setValues({ ...values, order_id: e.target.value, oid: "" })
                      }
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={`${t("crane")}`}
                    value={values.crane as any}
                    options={cranes.map((crane) => ({
                      value: crane.code,
                      label: crane.busy ? (
                        <>
                          <div style={{ paddingRight: "8px" }}>
                            {`${crane.description} `}
                          </div>
                          <CustomTag
                            label={`${t("busy")}`}
                            type="filled"
                            color={redColor}
                          />
                        </>
                      ) : (
                        crane.description
                      ),
                    }))}
                    handleChange={(e) =>
                      setValues({ ...values, crane: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={`${t("craneOperator")}`}
                    value={values.crane_operator as any}
                    options={craneOperators.map((craneOperator) => ({
                      value: craneOperator.code,
                      label: craneOperator.busy ? (
                        <>
                          <div style={{ paddingRight: "8px" }}>
                            {`${craneOperator.description} `}
                          </div>
                          <CustomTag
                            label={`${t("busy")}`}
                            type="filled"
                            color={redColor}
                          />
                        </>
                      ) : (
                        craneOperator.description
                      ),
                    }))}
                    handleChange={(e) =>
                      setValues({ ...values, crane_operator: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label={`${t("signaler")}`}
                    value={values.signaler as any}
                    options={signalers.map((signaler) => ({
                      value: signaler.code,
                      label: signaler.busy ? (
                        <>
                          <div style={{ paddingRight: "8px" }}>
                            {`${signaler.description} `}
                          </div>
                          <CustomTag
                            label={`${t("busy")}`}
                            type="filled"
                            color={redColor}
                          />
                        </>
                      ) : (
                        signaler.description
                      ),
                    }))}
                    handleChange={(e) =>
                      setValues({ ...values, signaler: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            }
            actions={[
              <CustomButton
                fullWidth
                label={t("reset")}
                onClick={() =>
                  setValues({
                    oid: "",
                    order_id: "",
                    crane: 0,
                    crane_operator: 0,
                    shift: "",
                    signaler: 0,
                    controller: "",
                    date_shift: "",
                  })
                }
                confirm
                type="outlined"
              />,
              <CustomButton
                fullWidth
                label={t("ok")}
                onClick={handleSubmitAssign}
                type="contained"
              />,
            ]}
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};
export default AssignResources;
