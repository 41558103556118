   import {
    methodGet,
    methodPost,
    methodPut,
    verticalFetch,
    validateMessageFetch
  } from "./httpRequests";

  import {
    getRawMessagesUrl,
    getRawMessagUrl,
    validateMessageUrl,
    resendRawMessageUrl,
    deleteMessageUrl,
  } from "./url";

  import { 
    DeleteMessageRequest 
  } from "../requests/messageService";

  
  export const getAllRawMessages = () => {
    return verticalFetch(methodGet, getRawMessagesUrl);
  };

  export const getRawMessage = (id: string) => {
    return verticalFetch(methodGet, getRawMessagUrl(id));
  }

  export const validateMessage = (body: string) => {
    return validateMessageFetch(methodPost, validateMessageUrl, body);
  }

  export const resendRawMessage = (id: string, body: string) => {
    return validateMessageFetch(methodPost, resendRawMessageUrl(id), body);
  }

  export const deleteMessage = (uid: string, body: DeleteMessageRequest) => {
    return verticalFetch(methodPut, deleteMessageUrl(uid), JSON.stringify(body));
  };